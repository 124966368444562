import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { HeadingText, BodyText, Button } from '../../styles'
import media from '../../styles/media'

const HeroCon = styled.div`
  height: 100vh;
  overflow: hidden;
  .hero-image {
    position: absolute !important;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }
  .text {
    margin: var(--gutter-xl) var(--gutter-s);
    p {
    }
  }
  ${media.laptop`
    .text {
      margin: var(--gutter-xxl) var(--gutter-m);
    }
  `}
`

const Four = () => {
  const data = useStaticQuery(graphql`
    query {
      homeImg: file(relativePath: { eq: "404Img.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeroCon>
      <Img
        durationFadeIn={250}
        objectFit="cover"
        fluid={data.homeImg.childImageSharp.fluid}
        className="hero-image"
      />
      <div className="text">
        <HeadingText>Something went wrong</HeadingText>
        <BodyText className="smallWidth">
          Sorry, we couldn’t find what you were looking for - try searching
          again or navigate back ot the home page.
        </BodyText>
        <br />
        <br />
        <Button onClick={() => navigate('/')}>Go Back</Button>
      </div>
    </HeroCon>
  )
}

export default Four
