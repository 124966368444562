import React from 'react'

import SEO from '~/components/seo'
import Four from '../components/Four'
import Footer from '../components/Footer'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Four />
    <Footer />
  </>
)

export default NotFoundPage
